// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fortune-wheel-button_k3Se7{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:flex;height:44px;padding:3px 4px;color:var(--AlertButtonText);text-transform:uppercase;background:radial-gradient(71.59% 71.59% at 49.79% 28.41%,#e2d8ae69 0,#745d3469 100%),radial-gradient(36.01% 145.45% at 46.47% 50%,#e7dfba66 0,hsla(50,42%,72%,.284) 100%),#ad9652;border:none;border-radius:5px}@media(hover:hover)and (pointer:fine){.fortune-wheel-button_k3Se7:not(.fortune-wheel-button--is-label_xJIpA):hover{cursor:pointer}.fortune-wheel-button_k3Se7:not(.fortune-wheel-button--is-label_xJIpA):hover .fortune-wheel-button__inner_Ro5tu{background:radial-gradient(119.74% 119.74% at 49.79% -19.74%,rgba(255,246,207,.064) 0,#d9d9d900 100%) padding-box;box-shadow:0 1px 4px #69522880}}.fortune-wheel-button__inner_Ro5tu{display:flex;gap:4px;align-items:center;justify-content:center;width:100%;height:100%;padding:0 10px;text-shadow:0 2px 6px #0000004d;background:radial-gradient(119.74% 119.74% at 49.79% -19.74%,hsla(48,48%,81%,.448) 0,#d9d9d900 100%) padding-box;border-color:#0000;border-style:solid;border-width:1px;border-radius:3px;box-shadow:0 1px 4px #695228;transition:all .15s ease-in-out}.fortune-wheel-button--is-label_xJIpA{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;height:40px;text-transform:none}.fortune-wheel-button--full-width_LeHA8{width:100%}.fortune-wheel-button--icon-only_EgMbq{justify-content:center;width:44px;height:44px;padding:3px;border-radius:50%}.fortune-wheel-button--icon-only_EgMbq .fortune-wheel-button__inner_Ro5tu{padding:0;border-radius:50%}html.app__layout--desktop .fortune-wheel-button_k3Se7:not(.fortune-wheel-button--is-label_xJIpA):not(.fortune-wheel-button--icon-only_EgMbq){height:54px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fortune-wheel-button": `fortune-wheel-button_k3Se7`,
	"fortune-wheel-button--is-label": `fortune-wheel-button--is-label_xJIpA`,
	"fortune-wheel-button__inner": `fortune-wheel-button__inner_Ro5tu`,
	"fortune-wheel-button--full-width": `fortune-wheel-button--full-width_LeHA8`,
	"fortune-wheel-button--icon-only": `fortune-wheel-button--icon-only_EgMbq`
};
export default ___CSS_LOADER_EXPORT___;
