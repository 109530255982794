// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("web/src/assets/images/fortune-wheel/back_mobile.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("web/src/assets/images/fortune-wheel/back.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fortune-wheel-page_ViB14{position:relative;display:flex;align-items:center;justify-content:center;min-width:375px;min-height:100vh;overflow:hidden;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:50%}.fortune-wheel-page_ViB14:after{position:absolute;right:0;bottom:0;left:0;height:210px;pointer-events:none;content:"";background:radial-gradient(74.89% 74.89% at 50% 79.19%,#0b0d0eb3 0,#0b0d0e00 100%)}.fortune-wheel-page__header_Zkixq{z-index:9;position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:space-between;width:100%;padding:12px 16px}html.app__layout--desktop .fortune-wheel-page__header_Zkixq{left:50%;flex-direction:row-reverse;gap:16px;justify-content:flex-start;max-width:1440px;transform:translateX(-50%)}.fortune-wheel-page__text_nNTkX{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;gap:4px;align-items:center;padding:8px;color:var(--BrandText);background-color:var(--BrandDefault);border:1px solid var(--BrandText);border-radius:4px}html.app__layout--desktop .fortune-wheel-page_ViB14{align-items:flex-end;min-width:946px;min-height:calc(100vh - 56px);padding-top:45px;margin:-8px -16px 0;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-position:center calc(100% + 190px);background-size:1920px}@media(min-width:1940px){html.app__layout--desktop .fortune-wheel-page_ViB14{min-height:1190px;background-position:center calc(100% + 260px);background-size:2560px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fortune-wheel-page": `fortune-wheel-page_ViB14`,
	"fortune-wheel-page__header": `fortune-wheel-page__header_Zkixq`,
	"fortune-wheel-page__text": `fortune-wheel-page__text_nNTkX`
};
export default ___CSS_LOADER_EXPORT___;
